@import './variables';
@import './spacingBootstrap';

.Cookie--base {
  background: $theme-color !important;
  border-top: 2px solid white !important;
  color: white !important;
}

.Cookie__button {
  color: #fff !important;
  background-color: $primary-color !important;
  border-color: #2e6da4 !important;
}

#modal-cookie-banner {
  background-color: rgba(211,211,211, 0.8);
}