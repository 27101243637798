.error-msg {
    margin-top: 5px;
    color: $error-color;
}

.error-message {
    margin-top: 5px;
    color: $error-color;
}

.form-group--error {
    border-color: $error-color !important;
}

.form-group--error > .vs__dropdown-toggle {
    border-color: $error-color !important;
}