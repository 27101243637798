@import './components/variables';
@import './components/error';
@import './components/cookieBannerDefault';

@media (min-width: 980px){
    .second-div-menu-displaying {
        padding-top: 0px;
    }
}

@media (max-width: 980px){
    .more-option {
        margin-bottom: 30px;
    }
}

.img-exemple2 {
    position: fixed;
    top: 0;
    left: 0;
    // width: 550px;
}

.img-exemple1 {
    height: 350px;
    float: left;
}

.account-user-sec {
		background-image: url('/img/fond-fil.png');
		background-position: 0 125px;
		background-repeat: no-repeat;
}

.bounce {
	-webkit-animation: bounce 2s;
	        animation: bounce 2s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
	60% {
		-webkit-transform: translateY(-12px);
		        transform: translateY(-12px);
	}
}

@keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
	60% {
		-webkit-transform: translateY(-12px);
		        transform: translateY(-12px);
	}
}
